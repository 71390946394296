import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './styles.module.css';
import mairaFood from '../../images/cd83a8d7-9b45-4a80-9c16-6a110c250e29.JPG';
import fullLogo from '../../images/MairaLareTransparent.png';
import shawarma from '../../images/758a1973-ed49-4c72-bdb9-750477a42f9e.JPG';
import shararmaToGo from '../../images/ChickenSharmaToGo.JPG';
import shararmaPlate from '../../images/food4_edited.jpg';
import { mdiChevronDown, mdiChevronLeft, mdiChevronRight } from '@mdi/js';
import Icon from '@mdi/react';

export const Home = () => {
    const history = useHistory();
    const [mobileBool, setMobileBool] = useState(false);
    //const prevMobileBool = usePre

    const [windowDimension, setWindowDimension] = useState(720);
    const reviews = [
        { review: '"The best chicken shawarma I’ve ever had. The portion is so generous and everything was fresh. I had rice, chicken and a salad. I also added an extra pita because they are so good! Definitely will be returning. Authentic good eats."', customer: '—  Rachel Craft' },
        { review: '"Great price! One of the best Mediterranean restaurant in town. Try their super delicious sandwiches."', customer: '—  Miraç Özkır' },
        { review: '"Really fresh, flavorful and delicious. Cannot wait to order from here again!"', customer: '— Muktiar ' },
        { review: '"Oh my word; just had my first visit and got the beef shawarma sandwich to-go and walked back to the office. this was fantastic; excellent ratio of meat to other fillings, and so delicious! This will be dangerously tempting to get on the regular."', customer: '—  Bob Lowrey' },
        { review: '"Very delicious food at a reasonable price. Hummus is very delicious. Staff are efficient and professional."', customer: '—  Marilyn Abdulla' },
        { review: `"Never disappoints! Everything is spiced just so, check for Daily Specials. Friday is Biriyani day. You won't want to miss that."`, customer: '—  Brian Tyler' },
        { review: `"Delicious, always consistent. I've never had a wrong order from Maira. They typically deliver before their estimated window and that is always a plus! Their delivery drivers are kind and respect the no-contact delivery requests. I would highly recommend Maira."`, customer: '—  Abbi' },
    ]
    const [currentReviewIndex, setCurrentReviewIndex] = useState(0);
    const currentReview = reviews[currentReviewIndex];

    const specials =
        [
            { day: 'Sunday', special: null },
            { day: 'Monday', special: 'Moroccan Chicken' },
            { day: 'Tuesday', special: 'Chicken Tikka Masala' },
            { day: 'Wednesday', special: 'Lamb Biryani' },
            { day: 'Thursday', special: 'Chicken Saag' },
            { day: 'Friday', special: 'Chicken Biryoni' },
            { day: 'Saturday', special: null }
        ]

    const currentSpecial = specials[new Date().getDay()];

    const nextReview = () => {
        setCurrentReviewIndex((prevIndex) => (prevIndex + 1) % reviews.length);
    };

    const prevReview = () => {
        setCurrentReviewIndex((prevIndex) => (prevIndex - 1 + reviews.length) % reviews.length);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentReviewIndex((prevIndex) => (prevIndex + 1) % reviews.length);
        }, 5000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        setWindowDimension(window.innerWidth);
    }, []);

    useEffect(() => {
        function handleResize() {
            setWindowDimension(window.innerWidth);
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);

    }, []);

    const isMobile = windowDimension <= 714;
    const isSmall = windowDimension <= 1500;


    useEffect(() => {
        // console.log(isMobile);
        if (isMobile !== mobileBool) {
            setMobileBool(isMobile);
        };

        return isMobile;

    }, [isMobile]);
    const Map = () => {
        // console.log('maps refreshing')
        return (
            <div>
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12535.944740431254!2d-85.6346328!3d38.2334279!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88690d5e1423c26d%3A0x5095eb509a596a02!2sMaira%20Mediterranean%20Grill!5e0!3m2!1sen!2sus!4v1694548751019!5m2!1sen!2sus"
                    width="100%"
                    height="450"
                    allowFullScreen={false}
                    loading="lazy"
                    style={{ pointerEvents: "none" }} // Disable user interaction
                    title="Maira Mediterranean Louisville, KY"
                    referrerPolicy="no-referrer-when-downgrade">
                </iframe>
            </div>
        );
    };

    return (
        <div className={styles.main}>

            <div className={styles.tOne} style={{ backgroundImage: 'url(' + mairaFood + ')', backgroundSize: 'cover', backgroundPosition: 'center', width: '100vw' }}>
                <div className={styles.container}>
                    <div className={styles.logoContainer}>
                        <img src={fullLogo}
                            alt="Maira Mediterranean Louisville, KY"
                            className={styles.centerLogo} />
                    </div>
                    <div className={styles.slogan}>
                        Curbside Pickup Up & Delivery Available
                    </div>
                    <div className={styles.clearBtn} onClick={() => { history.push('/order') }}
                        id='orderOnline'
                    >
                        Order Online
                    </div>


                    <Icon path={mdiChevronDown} className={styles.arrowD} size={4} />
                </div>
            </div>
            {!isMobile &&
                <div className={styles.tTwo}>
                    <div className={styles.multContainer}>
                        <div className={styles.boardRow}>
                            <div className={styles.menuBlock}>
                                {currentSpecial?.special != null &&
                                    <div className={styles.specialCont}>
                                        <div className={styles.specialContTitle}>Todays Special:</div>
                                        <div className={styles.dailySpecial}>{currentSpecial?.special}</div>
                                    </div>
                                }
                                <div className={styles.menuTitle}>
                                    menu
                                </div>
                                <div className={styles.menuBlockRow}>
                                    <div className={styles.menuList}>
                                        <div>Appetizers & Soups</div>
                                        <div>Main Dishes</div>
                                        <div>Salad & Vegetarian</div>
                                        <div>Kids Meals</div>
                                    </div>
                                    <div className={styles.menuList}>
                                        <div>Sandwiches</div>
                                        <div>Sides</div>
                                        <div>Deserts</div>
                                        <div>Beverages</div>
                                    </div>
                                </div>
                                <div className={styles.menuSpecial}>
                                    Friday Chicken Biriyani  Lunch Special
                                </div>
                                <div className={styles.whiteBtn}
                                    onClick={() => {
                                        history.push('/menu')
                                    }}
                                >
                                    View Menu
                                </div>

                            </div>
                            <div className={styles.rightImgCont}>
                                <img src={shawarma} style={{ height: 'auto', width: '100%', overflowY: 'clip', overflow: 'clip', overflowX: 'clip' }}
                                    alt="Chicken Shawarma"
                                />
                            </div>
                        </div>
                        <div className={styles.boardRow}>
                            {!isSmall &&
                                <div className={styles.sect1}>
                                    <img src={shararmaPlate} style={{ height: 'auto', width: '100%', overflowY: 'clip', overflow: 'clip', overflowX: 'clip' }}
                                        alt="Louisville Beef Shawarma"
                                    />
                                </div>
                            }
                            <div className={styles.sect2}>
                                <img src={shararmaToGo} style={{ height: 'auto', width: '100%', overflowY: 'clip', overflow: 'clip', overflowX: 'clip', overflowY: 'clip' }}
                                    alt="Mediterranean food to go"
                                />
                            </div>
                            <div className={styles.order}>
                                <div className={styles.orderTitle}>
                                    order
                                </div>
                                <div className={styles.subTitle}>
                                    takeout
                                </div>
                                <div className={styles.and}>
                                    and
                                </div>
                                <div className={styles.subTitle}>
                                    delivery
                                </div>
                                <div className={styles.clearBtn2} onClick={() => { history.push('/order') }}
                                    id='orderOnline' 
                                >
                                    Order Here
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}

            {isMobile &&
                <div className={styles.tTwo}>
                    <div className={styles.multContainerM}>
                        <div className={styles.boardRowM}>

                            <div className={styles.menuBlockMy}>
                                {currentSpecial?.special != null &&
                                    <div className={styles.specialCont}>
                                        <div className={styles.specialContTitle}>Todays Special:</div>
                                        <div className={styles.dailySpecial}>{currentSpecial?.special}</div>
                                    </div>
                                }
                                <div className={styles.menuTitle}>
                                    menu
                                </div>
                                <div className={styles.menuBlockRowM}>
                                    <div className={styles.menuList}>

                                        <div>Appetizers & Soups</div>
                                        <div>Main Dishes</div>
                                        <div>Salad & Vegetarian</div>
                                        <div>Kids Meals</div>
                                    </div>
                                    <div className={styles.menuList}>
                                        <div>Sandwiches</div>
                                        <div>Sides</div>
                                        <div>Deserts</div>
                                        <div>Beverages</div>
                                    </div>
                                </div>
                                <div className={styles.menuSpecial}>
                                    Friday Chicken Biriyani  Lunch Special
                                </div>
                                <div className={styles.whiteBtn}
                                    onClick={() => {
                                        history.push('/menu')
                                    }}
                                >
                                    View Menu
                                </div>

                            </div>
                            <div className={styles.rightImgContM}>
                                <img src={shawarma} style={{ height: 'auto', width: '100%', overflowY: 'clip', overflow: 'clip', overflowX: 'clip' }} />
                            </div>
                        </div>
                        <div className={styles.boardRowM}>
                            {!isSmall &&
                                <div className={styles.sect1}>
                                    <img src={shararmaPlate} style={{ height: 'auto', width: '100%', overflowY: 'clip', overflow: 'clip', overflowX: 'clip' }} />
                                </div>
                            }
                            <div className={styles.sect2}>
                                <img src={shararmaToGo} style={{ height: '100%', width: 'auto', overflowY: 'clip', overflow: 'clip', overflowX: 'clip', overflowY: 'clip' }} />
                            </div>
                            <div className={styles.orderM}>
                                <div className={styles.orderTitle}>
                                    order
                                </div>
                                <div className={styles.subTitle}>
                                    takeout
                                </div>
                                <div className={styles.and}>
                                    and
                                </div>
                                <div className={styles.subTitle}>
                                    delivery
                                </div>
                                <div className={styles.clearBtn2} onClick={() => { history.push('/order') }}>
                                    Order Here
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}



            <div className={styles.reviewCont}>
                <div className={styles.reviewBox}>

                    <div className={styles.menuTitle}>reviews</div>
                    <div className={styles.reviewRow}>
                        {!isMobile &&
                            <div className={styles.arrowCont} onClick={prevReview}
                            >
                                <Icon path={mdiChevronLeft} className={styles.arrow} size={3} />
                            </div>
                        }
                        <div className={styles.customerBox}>
                            <div className={styles.customerReview}
                            >{currentReview.review}</div>
                            <div className={styles.customerName}
                            >{currentReview.customer}</div>

                        </div>

                        {!isMobile &&
                            <div className={styles.arrowCont} onClick={nextReview}
                            >
                                <Icon path={mdiChevronRight} className={styles.arrow} size={3} />
                            </div>
                        }
                    </div>

                    {isMobile &&
                        <div className={styles.arrowRow}>
                            <div className={styles.arrowCont} onClick={prevReview}
                            >
                                <Icon path={mdiChevronLeft} className={styles.arrow} size={3} />
                            </div>
                            <div className={styles.arrowCont} onClick={nextReview}
                            >
                                <Icon path={mdiChevronRight} className={styles.arrow} size={3} />
                            </div>

                        </div>
                    }

                </div>
            </div>

            <div className={styles.gBanner}
            >
                <div className={styles.collumn}>
                    <div className={styles.address}>
                        - 4000 Dutchmans Ln, Louisville, KY 40207 -
                    </div>
                    <div className={styles.number}>-   502-891-0388   -</div>

                </div>
            </div>
            {Map()}
            <div className={styles.gBanner2}
            >
                <div className={styles.row}>
                    <div className={styles.padding}>Mon-Fri  10:30am - 8:00pm</div>
                    <div className={styles.padding}>Sat 11:00am - 8:00pm</div>
                    <div className={styles.padding}>Sun Closed</div>

                </div>
            </div>






        </div>
    );
};