import styles from './styles.module.css';
import uberEats from '../../images/UberEats.png';
import doorDash from '../../images/DoorDash.png';
import postMates from '../../images/postmates.png';
import doorDash2 from '../../images/doorDashB.png';
//import  from '../../images/.png';
export const Order = () => {
    // <div className={styles.txt}></div>
    //console.log('isMobile', isMobile);
    const openLink = (url) => {
        window.open(url, '_blank'); // '_blank' opens the link in a new tab/window
    };
    /* <div className={styles.row}>
                    <div className={styles.orderLogo}
                    onClick={() => {
                    //    / window.location('')
                        openLink('https://www.doordash.com/store/maira-mediterranean-grill-louisville-233989/')
                    }}
                    >
                        <img src={doorDash} height={'50px'} />
                    </div>
                    <div className={styles.orderLogo}>
                        <img src={uberEats} height={'50px'} />
                    </div>
                    <div className={styles.orderLogo}>
                        <img src={postMates} height={'50px'} />
                    </div>
                </div>
                */
    return (
        <div className={styles.main}>
            <div className={styles.container}>
                <div className={styles.titleBanner}
                >order</div>

                <div className={styles.row}>
                    <div className={styles.txt}>We Serve Halal Meat</div>
                    <div className={styles.boldTxt}>502-891-0388</div>
                    <div className={styles.txt}>We Offer Catering</div>
                </div>

                <div className={styles.row}>
                    <div className={styles.smallTxt}>Mon-Fri -   10:30am - 8:30pm </div>
                    <div className={styles.smallTxt}>Sat -  10:30am - 8:30pm</div>
                    <div className={styles.smallTxt}>Sun -  Closed</div>
                </div>
                <div className={styles.row}>
                    <div className={styles.dTxt}>Dine-in, Delivery, and Curbside Pick Up </div>
                </div>

                <div className={styles.collumn}>
                    <div className={styles.ddLogo} onClick={() => { openLink('https://www.doordash.com/store/maira-mediterranean-grill-louisville-233989/') }} id='doorDashOrder'
                        >
                        <img src={doorDash2} height={'180px'} />
                    </div>
                    <div className={styles.clearBtn} onClick={() => { openLink('https://www.doordash.com/store/maira-mediterranean-grill-louisville-233989/') }} id='doorDashOrder'
                    >Order Now</div>
                </div>

                <div className={styles.collumn}>
                    <div className={styles.ddLogo} onClick={() => { openLink('https://www.ubereats.com/store/maira-mediterranean-grill/wLC6oJpvWqCE5vDNdfYt-w') }} id='uberEatsOrder'
                        >
                        <img src={uberEats} height={'180px'} style={{ marginBottom: '12px' }} />
                    </div>
                    <div className={styles.clearBtn} onClick={() => { openLink('https://www.ubereats.com/store/maira-mediterranean-grill/wLC6oJpvWqCE5vDNdfYt-w') }} id='uberEatsOrder'
                    >Order Now</div>
                </div>
            </div>
        </div>
    )
}